<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <h2 class="d-flex justify-content-center">Tabel Provinsi</h2>
      <div class="row mb-8 mt-8 d-flex justify-content-center">
        <apexchart
          ref="provinsiChart"
          type="pie"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6">
          <b-card
            align="left"
            class="mb-10 shadow"
            style="border-radius: 15px; width: 288px; height: 140px; background-color: #D7E3F9"
          >
            <b-card-text>
              <i class="fa fa-map-pin" style="color: #18328D"></i>
            </b-card-text>
            <b-card-text
              style="font-size: 25px; line-height: 30px; color: #464E5F; font-weight: bold;"
            >
              {{ this.totalProvinsi }}
            </b-card-text>
            <b-card-text
              style="font-weight: 600; font-size: 12px; line-height: 8px; color: #464E5F;"
              >Total Provinsi</b-card-text
            >
          </b-card>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <b-card
            align="left"
            class="mb-10 shadow"
            style="border-radius: 15px; width: 288px; height: 140px; background-color: #F4F4E1"
          >
            <b-card-text>
              <i class="fa fa-map-pin" style="color: #18328D" />
            </b-card-text>
            <b-card-text
              style="font-size: 25px; line-height: 30px; color: #464E5F; font-weight: bold;"
            >
              {{ this.totalKabKota }}
            </b-card-text>
            <b-card-text
              style="font-weight: 600; font-size: 12px; line-height: 8px; color: #464E5F;"
              >Kabupaten/Kota</b-card-text
            >
          </b-card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-custom gutter-b card-stretch">
            <!--begin::Body-->
            <div class="card-body">
              <div class="justify-content-between d-flex">
                <div class="d-flex">
                  <b-form
                    class="mr-5"
                    style="width: 300px; border-radius: 4px;"
                    @submit.prevent
                  >
                    <div
                      class="input-group input-group-solid input-group-sm"
                      style="background: #DAE1EC; height: 38px"
                    >
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fa fa-search"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="search"
                        autocomplete="off"
                        placeholder="Search"
                        v-model="search"
                        ref="search"
                        @keyup.enter="searchFilterProvStats()"
                      />
                      <button
                        id="button-addon2"
                        class="btn btn-cari"
                        type="button"
                        @click="searchFilterProvStats"
                      >
                        <span v-if="!loading"
                          ><span class="svg-icon svg-icon-sm">
                            <inline-svg src="/media/svg/custom/search.svg" />
                          </span>
                        </span>
                        <b-spinner medium v-else></b-spinner>
                      </button>
                    </div>
                  </b-form>
                  <b-form class="mr-5">
                    <b-form-group
                      id="input-group-3"
                      label=""
                      label-for="input-3"
                    >
                      <b-form-select
                        id="input-3"
                        v-model="form.badan"
                        style="background-color: #dae1ec"
                        :options="badans"
                        @change="onSelectBadan"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >Bentuk Kelembagaan</b-form-select-option
                          >
                        </template>
                        <template>
                          <b-form-select-option :value="'0'"
                            >-</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-form>
                </div>
                <div class="d-flex">
                  <b-form class="mr-5">
                    <b-dropdown split text="Cetak" variant="secondary">
                      <b-dropdown-item href="#" @click="exportToExcel"
                        >Excel</b-dropdown-item
                      >
                      <b-dropdown-item href="#" @click="exportToPDF"
                        >PDF</b-dropdown-item
                      >
                    </b-dropdown>
                  </b-form>

                  <b-form>
                    <button
                      type="button"
                      class="hidden-button"
                      @click="refreshData"
                      id="refreshButton"
                      >
                      <i class="fas fa-sync-alt"></i>
                    </button>
                    <router-link :to="{ name: 'TambahProvinsi' }">
                      <b-button
                        style="background: #0A65FF; border-radius: 4px; color: #ffffff; font-weight: 600"
                        :class="
                          hasPermission('user/add') || hasPermission('kesbangpol/add') ? '' : 'hidden-button'
                        "
                      >
                        <i class="fa fa-plus-circle" style="color: #ffffff;"></i
                        >Tambah Data</b-button
                      >
                    </router-link>
                  </b-form>
                </div>
              </div>
              <!--begin::Table-->
              <DxDataGrid
                :ref="dataGridRefKey"
                :data-source="dataSource"
                key-expr="id"
                :show-borders="true"
                :word-wrap-enabled="true"
                :remote-operations="true"
              >
                <!-- <DxFilterRow :visible="true" /> -->
                <DxScrolling
                  :use-native="true"
                  :scroll-by-content="true"
                  :scroll-by-thumb="true"
                  show-scrollbar="onHover"
                />
                <DxColumn data-field="provinsi.name" caption="Daerah" :width="150" />
                <DxColumn
                  data-field="bentuk_kelembagaan.name"
                  caption="Bentuk Kelembagaan"
                  :width="160"
                />
                <DxColumn
                  data-field="nomenklatur"
                  caption="Nomenklatur Kelembagaan"
                  :width="250"
                />
                <DxColumn data-field="alamat" caption="Alamat Kantor" :width="250" />
                <DxColumn data-field="nama_pimpinan" caption="Nama Pimpinan" :allow-filtering="false" :width="150" />
                <DxColumn data-field="no_telp" caption="No. Telepon / Whatsapp Kantor" :allow-filtering="false" :width="150" />
                <DxColumn data-field="email" caption="Email Kantor" :width="150" />
                <DxColumn
                  :calculate-cell-value="downloadUrls"
                  data-field="perda_pembentukan"
                  caption="Perda Pembentukan"
                  cell-template="cellTemplatePerda"
                  :allow-exporting="false"
                  :width="150"
                />
                <template #cellTemplatePerda="{ data }">
                  <div v-html="data.value"></div>
                </template>
                <DxColumn
                  :calculate-cell-value="downloadStruktur"
                  data-field="struktur_organisasi"
                  caption="Struktur Organisasi"
                  cell-template="cellTemplateStruktur"
                  :allow-exporting="false"
                  :width="150"
                />
                <template #cellTemplateStruktur="{ data }">
                  <div v-html="data.value"></div>
                </template>
                <DxColumn
                  :calculate-cell-value="downloadPersonil"
                  data-field="nama_personil"
                  caption="Nama Personil"
                  cell-template="cellTemplatePersonil"
                  :allow-exporting="false"
                  :width="150"
                />
                <template #cellTemplatePersonil="{ data }">
                  <div v-html="data.value"></div>
                </template>
                <DxColumn
                  data-field="keterangan"
                  caption="Keterangan"
                  :allow-filtering="false"
                  :width="250"
                />
                <DxColumn type="buttons" caption="Aksi" :fixed="true">
                  <DxButton
                    icon="fa fa-eye"
                    hint="Lihat"
                    :onClick="onClickView"
                    :visible="hasPermission('user/view') || hasPermission('kesbangpol/view')"
                  />
                  <DxButton
                    icon="fa fa-edit"
                    hint="Ubah"
                    :onClick="onClickEdit"
                    :visible="hasPermission('user/update') || hasPermission('kesbangpol/update')"
                  />
                  <DxButton
                    icon="fa fa-trash"
                    hint="Hapus"
                    :onClick="onClickDelete"
                    :visible="hasPermission('user/delete') || hasPermission('kesbangpol/delete')"
                  />
                  <DxButton
                    icon="fa fa-arrow-right"
                    hint="Kabupaten/Kota"
                    :onClick="onClickTambah"
                  />
                </DxColumn>
                <DxPaging :page-size="10" />
                <DxPager
                  :visible="true"
                  :show-page-size-selector="showPageSizeSelector"
                  :allowed-page-sizes="pageSizes"
                />
              </DxDataGrid>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--   Modal View Kelembagaan Provinsi  -->
    <b-modal
      v-model="showModalView"
      size="xl"
      hide-footer
      centered
      scrollable
      id="modal-view-kelembagaan"
    >
      <template #modal-header="{ close }">
        <h5 class="title-card-custom">
          Kelembagaan Provinsi {{ formView.provinsi }}
        </h5>
      </template>
      <b-form>
        <b-form-group id="provinsi-group" label-for="provinsi" label="Daerah">
          <b-form-input
            id="provinsi"
            name="provinsi"
            disabled
            v-model="formView.provinsi"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="bentuk_kelembagaan-group"
          label-for="bentuk_kelembagaan"
          label="Bentuk Kelembagaan"
        >
          <b-form-input
            id="bentuk_kelembagaan"
            name="bentuk_kelembagaan"
            disabled
            v-model="formView.bentuk_kelembagaan"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="nomenklatur-group"
          label-for="nomenklatur"
          label="Nomenklatur Kelembagaan"
        >
          <b-form-input
            id="nomenklatur"
            name="nomenklatur"
            disabled
            v-model="formView.nomenklatur"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="alamat-group" label-for="alamat" label="Alamat Kantor">
          <b-form-input
            id="alamat"
            name="alamat"
            disabled
            v-model="formView.alamat"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="nama_pimpinan-group"
          label-for="nama_pimpinan"
          label="Nama Pimpinan"
        >
          <b-form-input
            id="nama_pimpinan"
            name="nama_pimpinan"
            disabled
            v-model="formView.nama_pimpinan"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="no_telp-group"
          label-for="no_telp"
          label="No. Telepon/Whatsapp Kantor"
        >
          <b-form-input
            id="no_telp"
            name="no_telp"
            disabled
            v-model="formView.no_telp"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="email-group" label-for="email" label="Email Kantor">
          <b-form-input
            id="email"
            name="email"
            disabled
            v-model="formView.email"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="perda_pembentukan-group"
          label-for="perda_pembentukan"
          label="Perda Pembentukan"
        >
          <b-form-input
            id="perda_pembentukan"
            name="perda_pembentukan"
            disabled
            v-model="formView.perda_pembentukan"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="struktur_organisasi-group"
          label-for="struktur_organisasi"
          label="Struktur Organisasi"
        >
          <b-form-input
            id="struktur_organisasi"
            name="struktur_organisasi"
            disabled
            v-model="formView.struktur_organisasi"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="nama_personil-group"
          label-for="nama_personil"
          label="Nama Personil"
        >
          <b-form-input
            id="nama_personil"
            name="nama_personil"
            disabled
            v-model="formView.nama_personil"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="keterangan-group"
          label-for="keterangan"
          label="Keterangan"
        >
          <b-form-input
            id="keterangan"
            name="keterangan"
            disabled
            v-model="formView.keterangan"
          ></b-form-input>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button
            type="reset"
            class="btn btn-delete mr-8"
            @click="closeModal()"
          >
            Tutup
          </button>
        </div>
      </b-form>
    </b-modal>

    <!--   Modal Edit Kelembagaan Provinsi  -->
    <b-modal
      v-model="showModalEdit"
      size="xl"
      hide-footer
      centered
      scrollable
      id="modal-edit-kelembagaan"
    >
      <template>
        <h5 class="title-card-custom">
          Kelembagaan Provinsi {{ form.provinsi }}
        </h5>
      </template>
      <b-form>
        <b-form-group id="provinsi-group" label-for="provinsi" label="Daerah">
          <b-form-input
            id="provinsi"
            name="provinsi"
            v-model="form.provinsi"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="bentuk_kelembagaan-group"
          label-for="bentuk_kelembagaan"
          label="Bentuk Kelembagaan"
        >
          <b-form-input
            id="bentuk_kelembagaan"
            name="bentuk_kelembagaan"
            v-model="form.bentuk_kelembagaan"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="nomenklatur-group"
          label-for="nomenklatur"
          label="Nomenklatur Kelembagaan"
        >
          <b-form-input
            id="nomenklatur"
            name="nomenklatur"
            v-model="form.nomenklatur"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="alamat-group" label-for="alamat" label="Alamat Kantor">
          <b-form-input
            id="alamat"
            name="alamat"
            v-model="form.alamat"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="nama_pimpinan-group"
          label-for="nama_pimpinan"
          label="Nama Pimpinan"
        >
          <b-form-input
            id="nama_pimpinan"
            name="nama_pimpinan"
            v-model="form.nama_pimpinan"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="no_telp-group"
          label-for="no_telp"
          label="No. Telepon/Whatsapp Kantor"
        >
          <b-form-input
            id="no_telp"
            name="no_telp"
            v-model="form.no_telp"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="email-group" label-for="email" label="Email Kantor">
          <b-form-input
            id="email"
            name="email"
            v-model="form.email"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="perda_pembentukan-group"
          label-for="perda_pembentukan"
          label="Perda Pembentukan"
        >
          <b-form-input
            id="perda_pembentukan"
            name="perda_pembentukan"
            v-model="form.perda_pembentukan"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="struktur_organisasi-group"
          label-for="struktur_organisasi"
          label="Struktur Organisasi"
        >
          <b-form-input
            id="struktur_organisasi"
            name="struktur_organisasi"
            v-model="form.struktur_organisasi"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="nama_personil-group"
          label-for="nama_personil"
          label="Nama Personil"
        >
          <b-form-input
            id="nama_personil"
            name="nama_personil"
            v-model="form.nama_personil"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="keterangan-group"
          label-for="keterangan"
          label="Keterangan"
        >
          <b-form-input
            id="keterangan"
            name="keterangan"
            v-model="form.keterangan"
          ></b-form-input>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button
            type="reset"
            class="btn btn-delete mr-8"
            @click="closeModal()"
          >
            Tutup
          </button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxButton,
  DxPaging,
  DxPager,
  DxScrolling
} from "devextreme-vue/data-grid";
import "devextreme/dist/css/dx.light.css";
import CustomStore from "devextreme/data/custom_store";
import { handleErrors, isNotEmpty } from "@/core/appUtil/util";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { mapGetters } from "vuex";

let token = "";
let searchQ = "";
let badanQ = "0";

const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      // Set default sorting
      if (i == 'sort' && !isNotEmpty(loadOptions[i])) {
        params += `${i}=[{"selector":"provinsi_id","desc":false}]&`;
      }

      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists
    if (searchQ.length > 0) {
      // params += "&nomenklatur=" + searchQ;
      if (params.indexOf("?") > -1) {
        params += "&";
      } else {
        params += "?";
      }

      params += "q=" + searchQ;
    }

    if (badanQ !== "0") {
      params += "&bentuk_kelembagaan_id=" + badanQ;
    }
    if (
      Array.isArray(loadOptions["filter"]) &&
      loadOptions["filter"].length > 0 &&
      loadOptions["filter"][0] === "name"
    ) {
      params += "&nama=" + loadOptions["filter"][2];
    }
    return fetch(
      process.env.VUE_APP_URL_LOCAL + `/kelembagaan-provinsi${params}`
    )
      .then(response => response.json())
      .then(data => {
        if (data.data === null) {
          setTimeout(() => {
            document.querySelector("#refreshButton").click();
          }, 500);

        } else {
          return {
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount
          };
        }
      })
      .catch(() => {
        throw "Data Loading Error";
      });
  }
});
const dataGridRefKey = "advocacy-data-grid";

const swalDelete = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-delete",
    cancelButton: "btn btn-cancel"
  },
  buttonsStyling: false
});
const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  name: "KelembagaanProvinsi",
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxPaging,
    DxPager,
    DxScrolling
  },
  data() {
    return {
      showModalEdit: false,
      showModalView: false,
      dataSource: store,
      dataGridRefKey,
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      form: {
        id: null,
        // pilihan: null,
        // badan: null,
        // keterangan: null,
        // kasus_hukum: "",
        // materi: "",
        // perkara: "",
        // direktorat: null
        provinsi: "",
        bentuk_kelembagaan: "",
        nomenklatur: "",
        alamat: "",
        nama_pimpinan: "",
        no_telp: "",
        email: "",
        perda_pembentukan: "",
        struktur_organisasi: "",
        nama_personil: "",
        keterangan: "",
      },
      formView: {
        provinsi: "",
        bentuk_kelembagaan: "",
        nomenklatur: "",
        alamat: "",
        nama_pimpinan: "",
        no_telp: "",
        email: "",
        perda_pembentukan: "",
        struktur_organisasi: "",
        nama_personil: "",
        keterangan: ""
      },
      badans: [],
      statsProvinsi: [],
      totalProvinsi: 0,
      totalKabKota: 0,
      loading: false,
      search: "",
      series: [],
      chartOptions: {
        chart: {
          width: 480,
          type: "pie",
          toolbar: {
            show: true,
            tools: {
              download: true
            }
          }
        },
        title: {
          text: "Rekap Provinsi",
          align: "center"
        },
        labels: [],
        colors: [
          "#7CB5EC",
          "#434348",
          "#90ED7D",
          "#F7A35C",
          "#8085E9",
          "#F15C80",
          "#2B908F",
          "#E4D354"
        ],
        legend: {
          position: "bottom"
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            distributed: true
          }
        }
      }
    };
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    })
    .then(() => {
      this.getBentukKelembagaan()
      this.getTotalProvinsi()
      this.getTotalKabKot()
  
      this.getProvinsiStats();
    });
  },
  computed: {
    ...mapGetters({
      hasPermission: "token/hasPermission"
    }),
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  methods: {
    getTotalKabKot(attempt = 0) {
      attempt++;

      fetch(
        encodeURI(process.env.VUE_APP_URL_LOCAL + `/kelembagaan-kota?take=1000`),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          if (items.data === null && attempt <= 3) {
            this.getTotalKabKot(attempt);
          } else {
            this.totalKabKota = items.totalCount;
          }
        });
    },
    getTotalProvinsi(attempt = 0) {
      attempt++;

      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL + `/kelembagaan-provinsi?take=100`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          if (items.data === null && attempt <= 3) {
            this.getTotalProvinsi(attempt);

          } else {
            this.totalProvinsi = items.totalCount;
          }
        });
    },
    getBentukKelembagaan(attempt = 0) {
      attempt++;

      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL + `/master/bentuk-kelembagaan?take=100`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          if (items.data === null && attempt <= 3) {
            this.getBentukKelembagaan(attempt);

          } else {
            const detailMenu = items.data;
            let selectedOptions;
            selectedOptions = [];
    
            if (detailMenu) {
              detailMenu.map(i => {
                return selectedOptions.push({
                  text: i.name,
                  value: i.id
                });
              });
            }
    
            this.badans = selectedOptions;
          }
        });
    },
    getProvinsiStats() {
      fetch(
        encodeURI(
          process.env.VUE_APP_URL_LOCAL + `/kelembagaan-provinsi/stats`
        ),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          this.statsProvinsi = items.data;
          let selectedOptions;
          selectedOptions = [];
          let cats = [];
          let dats = [];
          this.statsProvinsi.map(i => {
            cats.push(i.name);
            dats.push(i.total);
            return selectedOptions.push({
              name: i.name,
              total: i.total
            });
          });
          this.series = dats;

          this.chartOptions = {
            ...this.chartOptions,
            ...{
              labels: cats
            }
          };
        });
    },
    closeModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-view-kelembagaan");
      });
    },
    refreshData() {
      this.dataGrid.refresh();
    },
    downloadUrls(rowData) {
      let paths = rowData.perda_pembentukan_path.split(";");
      let output = "";
      if (paths[0].length > 0) {
        output = `${rowData.perda_pembentukan}<br /><br /><ul>`;
        paths.forEach(path => {
          let fnames = path.split("/");
          let namaFile = fnames[1].substring(8);

          // START Check if new format file name
          let uniqueCode = fnames[1].split("-").pop();
          if (uniqueCode.length > 0) {
            uniqueCode = uniqueCode.split(".")[0];
          }

          let isUniqueCode = uniqueCode ? uniqueCode.length === 7 : false;
          if (isUniqueCode) {
            // Get string before extension
            namaFile = fnames[1].replace(`-${uniqueCode}`, "");
          }
          // END Check if new format file name

          output += `<li><a target="_blank" href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${path}">${namaFile}</a></li>`;
        });
        output += "</ul>";
      } else {
        output = `${rowData.perda_pembentukan}`;
      }
      return output;
    },
    downloadStruktur(rowData) {
      let paths = rowData.struktur_organisasi_path.split(";");
      let output = "";
      if (paths[0].length > 0) {
        output = `${rowData.struktur_organisasi}<br /><br /><ul>`;
        paths.forEach(path => {
          let fnames = path.split("/");
          let namaFile = fnames[1].substring(8);

          // START Check if new format file name
          let uniqueCode = fnames[1].split("-").pop();
          if (uniqueCode.length > 0) {
            uniqueCode = uniqueCode.split(".")[0];
          }

          let isUniqueCode = uniqueCode ? uniqueCode.length === 7 : false;
          if (isUniqueCode) {
            // Get string before extension
            namaFile = fnames[1].replace(`-${uniqueCode}`, "");
          }
          // END Check if new format file name

          output += `<li><a target="_blank" href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${path}">${namaFile}</a></li>`;
        });
        output += "</ul>";
      } else {
        output = `${rowData.struktur_organisasi}`;
      }
      return output;
    },
    downloadPersonil(rowData) {
      let paths = rowData.nama_personil_path.split(";");
      let output = "";
      if (paths[0].length > 0) {
        output = `${rowData.nama_personil}<br /><br /><ul>`;
        paths.forEach(path => {
          let fnames = path.split("/");
          let namaFile = fnames[1].substring(8);

          // START Check if new format file name
          let uniqueCode = fnames[1].split("-").pop();
          if (uniqueCode.length > 0) {
            uniqueCode = uniqueCode.split(".")[0];
          }

          let isUniqueCode = uniqueCode ? uniqueCode.length === 7 : false;
          if (isUniqueCode) {
            // Get string before extension
            namaFile = fnames[1].replace(`-${uniqueCode}`, "");
          }
          // END Check if new format file name

          output += `<li><a target="_blank" href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${path}">${namaFile}</a></li>`;
        });
        output += "</ul>";
      } else {
        output = `${rowData.nama_personil}`;
      }
      return output;
    },
    exportToExcel() {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Statistik");
      console.log(this.dataGrid);

      exportDataGrid({
        component: this.dataGrid,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Statistik.xlsx"
          );
        });
      });
    },
    exportToPDF() {
      const options = {
        columnWidth: "wrap",
        margin: { horizontal: 5 },
        bodyStyles: { valign: "top" },
        styles: { overflow: "linebreak", overflowColumns: true },
        headerStyles: {
          fillColor: [51, 122, 183],
          textColor: [255],
          halign: "center",
          valign: "middle"
        },
        theme: "grid"
      };
      const doc = new jsPDF("l", "pt", "a4");
      exportDataGridToPdf({
        jsPDFDocument: doc,
        autoTableOptions: options,
        component: this.dataGrid
      }).then(() => {
        doc.save("Statistik.pdf");
      });
    },
    onSelectBadan() {
      badanQ = this.form.badan;
      this.refreshData();
    },
    async searchFilterProvStats() {
      searchQ = this.search;
      let context = this;
      this.loading = true;
      this.notFound = false;
      this.show = false;
      await this.$store
        .dispatch("searchFilterProvStats", this.search)
        .then(res => {
          console.log(res);
          this.refreshData();
          this.loading = false;
        })
        .catch(async function(err) {
          context.loading = false;
          await Swal.fire({
            title: err.message,
            text: "",
            icon: "warning",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Tutup",
            cancelButtonText: false
          });
        });
    },
    onSubmit() {
      let id = this.form.id;
      let data = {
        direktorat_id: this.form.direktorat,
        kasus_hukum: this.form.kasus_hukum,
        materi: this.form.materi,
        perkara: this.form.perkara
      };

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(
              process.env.VUE_APP_URL_LOCAL + `/kelembagaan-provinsi/${id}`
            ),
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              Swal.fire({
                position: "center",
                icon: data.status,
                title: "Data berhasil diubah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.refreshData();
              this.getProvinsiStats();
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Gagal mengubah data",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        kasus_hukum: "",
        materi: "",
        perkara: "",
        direktorat: null
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-advokasi");
      });
    },
    onClickDelete(e) {
      let id = e.row.data.id;
      swalDelete.fire({
        text: "Yakin ingin hapus data?",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(
              process.env.VUE_APP_URL_LOCAL + `/kelembagaan-provinsi/${id}`
            ),
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              }
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              console.log(data);
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.refreshData();
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onClickTambah(e) {
      const data = e.row.data;
      this.$router.push({ name: "KelembagaanKabKota", params: { data } });
    },
    onClickEdit(e) {
      const data = e.row.data;
      // this.form.kasus_hukum = data.kasus_hukum;
      // this.form.materi = data.materi;
      // this.form.perkara = data.perkara;
      // this.form.direktorat = data.direktorat_id;
      // this.form.id = data.id;
      // this.$root.$emit("bv::show::modal", "modal-edit-kelembagaan", e.row.data);
      this.$router.push({ path: `ubah/${data.id}` });
    },
    onClickView(e) {
      const data = e.row.data;
      this.formView.provinsi = data.provinsi.name;
      this.formView.bentuk_kelembagaan = data.bentuk_kelembagaan.name;
      this.formView.nomenklatur = data.nomenklatur;
      this.formView.alamat = data.alamat;
      this.formView.nama_pimpinan = data.nama_pimpinan;
      this.formView.no_telp = data.no_telp;
      this.formView.email = data.email;
      this.formView.perda_pembentukan = data.perda_pembentukan;
      this.formView.struktur_organisasi = data.struktur_organisasi;
      this.formView.nama_personil = data.nama_personil;
      this.formView.keterangan = data.keterangan;
      this.$root.$emit("bv::show::modal", "modal-view-kelembagaan", e.row.data);
    }
  }
};
</script>

<style>
#grid-container {
  height: 440px;
}
.width-custom {
  width: 420px;
  height: 280px;
  display: flex;
  justify-content: center;
}
.title-card {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #000000;
}
</style>
